body .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.check-form-area,
.cartStatus {
  padding: 40px;
  box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);

  @media (max-width: 767px) {
    padding: 20px;
  }

  table {
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    .MuiTableCell-body {
      padding-left: 0;
    }
  }
}

.checkoutWrapper {
  .checkoutCard {
    margin-bottom: 20px;

    button.collapseBtn {
      color: #272424;
      height: 50px;
      font-size: 13px;
      letter-spacing: 0;
      display: block;
      text-align: left;
      text-transform: capitalize;
      font-weight: 600;
      border: 1px solid #dedddd;
      border-radius: 0;
      background: #fff;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
      padding: 0 15px;

      i {
        float: right;
        margin-top: 4px;
        color: #8e8d8a;
      }
    }

    .chCardBody {
      padding: 15px;
      border: 1px solid #f9f9f9;

      .formSelect {
        div {
          background: transparent;
          height: 27px;
          padding: 0;
          line-height: 30px;
          text-transform: capitalize;
          font-weight: 400;
          letter-spacing: 0;
          color: #555;
          font-size: 13px;
        }
      }

      .checkBox {
        span {
          font-size: 14px;
          letter-spacing: 0;
        }
      }

      .note {
        > div {
          margin-top: 30px;
        }

        textarea {
          height: 100px !important;
          padding: 0;
        }
      }

      .paymentMethod {
        display: flex;
        flex-direction: row;

        label {
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      label {
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 500;
        transform: scale(1);
      }

      input,
      textarea {
        height: 25px;
        background: transparent;
        padding-left: 10px;
        font-weight: 500;
        letter-spacing: 0;
        color: #444444;
        font-size: 13px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #555555;
      }
    }
  }

  .cuponWrap {
    .cuponForm {
      margin-top: 10px;

      button {
        margin-top: 10px;
        padding: 8px 25px;
        background-color: #333;
        color: #fff;
        &:hover {
          background-color: #b83806;
          color: #fff;
        }
      }
    }
  }

  .cardType {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px 20px;

    .cardItem {
      margin: 5px;
      flex-basis: calc(100% * (1 / 4) - 10px);
      text-align: center;
      border: 1px solid #ddd;
      padding: 18px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      cursor: pointer;

      &.active {
        background: #f9f9f9;
      }
    }

    a {
      width: 100%;
      text-align: center;
      padding: 10px;
      border-radius: 3px;
    }
  }
}

.ml-15 {
  margin-left: 15px;
}

.formFooter {
  button {
    padding: 8px 25px;
    background-color: #333;
    color: #fff;

    &:hover {
      background-color: #b83806;
      color: #fff;
    }
  }
}
