/*======================================
 13. wpo-invitation-area
 =======================================*/

.invitation-header {
  .navbar-brand {
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: $heading-font;
    color: $white;
    display: block;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 30px 0;
    align-items: center;

    li {
      .back-btn {
        display: block;
        width: 70px;
        height: 70px;
        line-height: 70px;
        background: $theme-primary-color;
        text-align: center;

        &:hover {
          background: darken($theme-primary-color, 6);
        }

        i {
          font-size: 30px;
          color: $white;
          line-height: unset;
        }
      }
    }
  }
}

/*11.1 wpo-invitation-area */

.wpo-invitation-area {
  .wpo-invitation-wrap {
    padding: 60px;
    background: $white;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      max-width: 1000px;
    }

    @media (max-width: 1199px) {
      max-width: 900px;
    }

    @media (max-width: 991px) {
      padding: 30px;
      max-width: 800px;
    }

    @media (max-width: 575px) {
      padding: 10px;
    }

    .wpo-invitation-inner {
      border: 1px solid $theme-primary-color-s3;
      padding: 110px 0;

      @media (max-width: 1199px) {
        padding: 80px 0;
      }

      @include media-query(991px) {
        padding: 60px 0;
      }

      @media (max-width: 575px) {
        padding: 30px 10px;
      }
    }

    .wpo-invitation-inner-item {
      border: 1px solid $theme-primary-color-s3;
      padding: 10px;

      @media (max-width: 575px) {
        padding: 5px;
      }
    }

    .middle-couple-pic {
      width: 660px;
      height: 660px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.06);
      background: $white;
      border-radius: 50%;
      margin-bottom: 80px;

      @media (max-width: 1440px) {
        width: 520px;
        height: 520px;
      }

      @include media-query(991px) {
        width: 450px;
        height: 450px;
      }

      @include media-query(575px) {
        width: 290px;
        height: 290px;
      }

      @include media-query(370px) {
        width: 230px;
        height: 230px;
        padding: 10px;
      }

      .couple-flower {
        position: absolute;
        left: 0;
        bottom: -75px;

        @media (max-width: 1440px) {
          bottom: -55px;
        }

        @media (max-width: 1199px) {
          bottom: -40px;
        }
      }

      .middle-couple-pic-inner {
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          -webkit-animation: kenburn 20s 1.2s alternate infinite;
          -animation: kenburn 20s 1.2s alternate infinite;
        }
      }

      @include media-query(991px) {
        margin-bottom: 90px;
      }

      @include media-query(370px) {
        margin-bottom: 60px;
      }
    }

    .wpo-invitation-info {
      max-width: 644px;
      margin: 0 auto;
      margin-top: 150px;
      text-align: center;

      @include media-query(1199px) {
        margin-top: 100px;
      }

      @include media-query(370px) {
        margin-top: 60px;
      }

      span {
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 1399px) {
          font-size: 25px;
        }

        @media (max-width: 1199px) {
          font-size: 20px;
        }
      }

      h2 {
        font-size: 90px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        margin-bottom: 20px;

        @media (max-width: 1399px) {
          font-size: 70px;
        }

        @media (max-width: 1199px) {
          font-size: 60px;
        }

        @media (max-width: 991px) {
          font-size: 35px;
        }

        @include media-query(370px) {
          font-size: 32px;
          margin-bottom: 10px;
        }

        span {
          display: inline-block;
          max-width: 30%;

          @media (max-width: 767px) {
            max-width: 20%;
          }

          @media (max-width: 480px) {
            display: none;
          }

          img {
            @media (max-width: 1399px) {
              max-width: 60%;
            }

            @media (max-width: 767px) {
              max-width: 100%;
            }
          }
        }
      }

      p {
        font-weight: 400;
        font-size: 25px;
        line-height: 36px;
        text-align: center;

        @media (max-width: 1199px) {
          font-size: 20px;
        }

        @media (max-width: 575px) {
          font-size: 18px;
        }

        @media (max-width: 480px) {
          font-size: 15px;
        }
      }

      .shape {
        img {
          @media (max-width: 575px) {
            max-width: 120px;
          }
        }
      }

      #clock {
        overflow: hidden;
        text-align: center;
        display: inline-block;
        margin-top: 0px;

        @media (max-width: 767px) {
          margin-top: 0;
        }

        .time-section {
          width: 70px;
          float: left;

          @include media-query(1199px) {
            width: 80px;
            height: 40px;
          }

          @include media-query(991px) {
            width: 52px;
            height: 50px;
          }
        }

        .time-section + .time-section {
          margin-left: 50px;

          @include media-query(991px) {
            margin-left: 20px;
          }

          @include media-query(767px) {
            margin-left: 10px;
          }
        }

        .time {
          font-family: $heading-font;
          font-size: 60px;
          line-height: 1em;
          padding-top: 15px;
          color: $theme-primary-color;
          margin-bottom: 20px;
          font-weight: 400;

          @include media-query(1199px) {
            font-size: 50px;
          }

          @include media-query(991px) {
            font-size: 40px;
          }
        }

        .time-text {
          color: $text-color;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;

          @include media-query(1199px) {
            font-size: 20px;
          }

          @include media-query(991px) {
            font-size: 18px;
          }
        }
      }
    }

    .wpo-event-text {
      h2 {
        padding: 10px 0;
        background: $theme-primary-color-s3;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $white;

        @media (max-width: 757px) {
          font-size: 25px;
        }
      }

      ul {
        list-style: none;
        max-width: 415px;
        margin: 0 auto;
        margin-top: 50px;
        text-align: center;

        @media (max-width: 991px) {
          margin-top: 40px;
        }

        li {
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 150.2%;
          margin-bottom: 30px;

          @include media-query(991px) {
            font-size: 18px;
          }

          @include media-query(370px) {
            font-size: 16px;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          button {
            color: $theme-primary-color-s2;
            position: relative;
            display: inline-block;
            margin-top: 10px;
            font-size: 22px;
            text-transform: capitalize;
            font-weight: 500;

            @include media-query(991px) {
              font-size: 18px;
            }

            @include media-query(370px) {
              font-size: 16px;
            }

            &:focus,
            &:hover {
              background: none;
              border: 0;
              box-shadow: none;
            }

            &::before {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              content: "";
              background: $theme-primary-color-s2;
            }
          }
        }
      }
    }
  }
}

/*======================================
 12. wpo-invitation-area-s2
 =======================================*/

.wpo-invitation-area-s2,
.wpo-coming-soon-area {
  height: 100vh;
  min-height: 920px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @include media-query(767px) {
    min-height: 650px;
  }

  @include media-query(550px) {
    min-height: 600px;
  }

  @include media-query(450px) {
    min-height: 600px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: transparentize($black, 0.7);
  }

  .container {
    @include media-query(767px) {
      max-width: 100%;
    }
  }

  .hero-inner {
    padding-top: 100px;

    @media (max-width: 991px) {
      padding-top: 0;
    }

    .row {
      justify-content: center;
    }
  }

  .wpo-event-wrap {
    border: 1px solid #fff;
    background: linear-gradient(
      140deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    padding: 30px;
    margin-top: 120px;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;

    @include media-query(767px) {
      padding: 20px;
    }
    @include media-query(450px) {
      padding: 10px;
    }

    .frame-shape-1 {
      position: absolute;
      left: 50%;
      top: -75px;
      transform: translateX(-50%);
      min-width: 558px;

      @include media-query(991px) {
        min-width: 300px;
        top: -36px;
      }

      @include media-query(350px) {
        min-width: 240px;
        top: -36px;
      }
    }

    .frame-shape-2 {
      position: absolute;
      left: 50%;
      bottom: -75px;
      transform: translateX(-50%);
      min-width: 558px;

      @include media-query(991px) {
        min-width: 300px;
        bottom: -36px;
      }

      @include media-query(350px) {
        min-width: 240px;
        bottom: -36px;
      }
    }
  }

  .wpo-event-item {
    padding: 40px 70px 70px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    background-size: contain;
    border: 1px solid $white;

    @media (max-width: 500px) {
      width: 100%;
      background: none;
      padding: 0;
    }

    .wpo-event-text {
      text-align: center;

      @media (max-width: 1200px) {
        padding: 25px 15px;
      }
      @media (max-width: 575px) {
        padding: 50px 10px;
      }

      @media (max-width: 500px) {
        background: rgba(255, 255, 255, 0.5);
      }

      h2 {
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 10px;

        @media (max-width: 991px) {
          font-size: 60px;
        }

        @media (max-width: 767px) {
          font-size: 50px;
        }

        @media (max-width: 450px) {
          font-size: 35px;
        }
      }

      p {
        font-weight: 400;
        font-size: 30px;
        line-height: 43px;
        margin-bottom: 30px;
        color: $text-color;

        @media (max-width: 991px) {
          font-size: 22px;
          line-height: 33px;
        }

        @include media-query(767px) {
          margin-bottom: 10px;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }

      .inner-shape {
        margin-bottom: 25px;
      }

      ul {
        list-style: none;
        max-width: 416px;
        margin: auto;

        li {
          margin-bottom: 25px;
          font-size: 20px;
          line-height: 30px;
          margin-left: 0;
          float: none;
          color: $text-color;

          @media (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 5px;
          }

          @media (max-width: 450px) {
            margin-bottom: 2px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          button {
            font-weight: 500;
            color: $theme-primary-color;
            position: relative;
            display: inline-block;
            text-transform: capitalize;
            font-size: 18px;

            @media (max-width: 767px) {
              margin-top: 10px;
            }

            &:focus,
            &:hover {
              background: none;
              border: 0;
              box-shadow: none;
            }

            &:before {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              content: "";
              background: $theme-primary-color;
            }
          }
        }
      }
    }

    .shape-1 {
      position: absolute;
      left: 0;
      top: 0;
    }

    .shape-2 {
      position: absolute;
      right: 0;
      top: 0;
    }

    .shape-3 {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .shape-4 {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
